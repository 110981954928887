import { ActivateMachine, DeActivateMachine, getLicense } from "./licensing";

//function to generate a license from keygen api
export async function checkKeygenLicense(Tid: string, userInfo: any) {
  const licenseCheck: any = await getLicense(Tid, userInfo);

  return licenseCheck;
}

export async function assignMachine(Tid: string, userInfo: any, licenseId: any) {
  // console.log('365APPS ~ assignMachine ~ Tid', Tid);

  console.log("365APPS ~ AssignMachine", userInfo.Name);

  // console.log('365APPS ~ assignMachine ~ userInfo', userInfo);

  let userObject = {
    userId: userInfo.key,
    email: userInfo.Email,
  };

  const machine = await ActivateMachine(Tid, userObject, licenseId.data.id);

  return machine;
}

export async function removeMachine(Tid: string, userInfo: any) {
  console.log("365APPS ~ removeMachine ", userInfo.Name);

  const machine = await DeActivateMachine(Tid, userInfo.key);

  return machine;
}
