import * as React from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { Autocomplete, Container, createTheme, Grid, Icon, InputAdornment, TextField, ThemeProvider } from "@mui/material";

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: any[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  //   disablePadding: boolean;
  id: string;
  label: string;
  //   numeric: boolean;
}

const headCells: HeadCell[] = [
  {
    id: "key",
    label: "Key",
  },
  {
    id: "Name",
    label: "Name",
  },
  {
    id: "Email",
    label: "Email",
  },
  {
    id: "jobTitle",
    label: "Job Title",
  },
  {
    id: "officeLocation",
    label: "Office Location",
  },
  {
    id: "assigned",
    label: "Action",
  },
];
type Order = "asc" | "desc";

interface EnhancedTableProps {
  numSelected: number;
  // onRequestSort: (event: React.MouseEvent<unknown>, property: Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // order: Order;
  // orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount } = props;
  const hc: any[] = headCells.slice(1);

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {hc.map((headCell: any) => (
          <TableCell key={headCell.id}>{headCell.label}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <></>
      )}
    </Toolbar>
  );
};

interface Data {
  key: string;
  Name: string;
  Email: string;
  jobTitle: string;
  officeLocation: string;
}

export interface ITableProps {
  data: Data[];
  thechosenOnes: (data: any[]) => void;
  selectedIndexes: Data[];
}

export const ETable: React.FunctionComponent<ITableProps> = (props: React.PropsWithChildren<ITableProps>) => {
  // export default function EnhancedTable(props:any) {

  // const rows = props.data;
  const [rows, setRows] = React.useState(props.data);
  // console.log("365APPS ~ EnhancedTable ~ props", props);

  const [order, setOrder] = React.useState<any>("asc");
  const [orderBy, setOrderBy] = React.useState<any>("Name");
  const [selected, setSelected] = React.useState<any[]>(props.selectedIndexes);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rawData, setrawData] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: any) => n);
      setSelected(newSelecteds);
      props.thechosenOnes(newSelecteds);
      return;
    } else {
      setSelected([]);
      props.thechosenOnes([]);
    }
  };

  const handleClick = (event: React.MouseEvent<unknown>, data: any[]) => {
    const selectedIndex = selected.indexOf(data);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, data);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    props.thechosenOnes(newSelected);

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };
  const handleChangeRaw = (event: React.ChangeEvent<HTMLInputElement>) => {
    setrawData(event.target.checked);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const [searched, setSearched] = React.useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setName(event.target.value);
    requestSearch(event.target.value);
    setSearched(event.target.value);
  };

  const requestSearch = (searchedVal: string) => {
    const filteredRows = props.data.filter((row) => {
      // console.log("365APPS ~ filteredRows ~ row", row);

      if (row.Email) {
        return row.Email.toLowerCase().includes(searchedVal.toLowerCase()); //ERROR
      } else {
        return row.Name.toLowerCase().includes(searchedVal.toLowerCase()); //ERROR
      }

      return row;
    });
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
    handleChange;
  };

  React.useEffect(() => {
    // console.log("365APPS ~ EnhancedTable ~ props", props);
    // console.log("365APPS ~ EnhancedTable ~ PRE selected", props.selectedIndexes);
    cancelSearch();
    setSelected(props.selectedIndexes);
  }, [props.selectedIndexes]);

  const mdTheme = createTheme({
    palette: {
      primary: {
        light: "#ff7097",
        main: "#fb3569",
        dark: "#c2003f",
        contrastText: "#fff",
      },
      secondary: {
        light: "#73e8ff",
        main: "#29b6f6",
        dark: "#0086c3",
        contrastText: "#fff",
      },
    },
  });

  return (
    <ThemeProvider theme={mdTheme}>
      {/* <Box
        sx={{
          width: "100%",
          backgroundColor: (theme) =>
            theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
        }}
      > */}
      <Paper sx={{ mb: 2, p: 2 }}>
        {/* <SearchBar
          value={searched}
          onChange={(searchVal: string) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
        /> */}
       

        <TextField id="outlined-name" label="Search" value={searched} onChange={handleChange} />
        <IconButton aria-label="toggle password visibility" onClick={cancelSearch} edge="end">
          <CancelIcon></CancelIcon>
        </IconButton>
   
     
        <EnhancedTableToolbar numSelected={selected.length} 
          
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
            <EnhancedTableHead
              numSelected={selected.length}
              // order={order}
              // orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              // onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with: */}
              {rows
                .slice()
                //   .sort(getComparator(order, orderBy))

                // {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index: any) => {
                  const isItemSelected = isSelected(row);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.Name}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.Name}
                      </TableCell>
                      {/* <TableCell align="right">{row.Name}</TableCell> */}
                      <TableCell align="left">{row.Email}</TableCell>
                      <TableCell align="left">{row.jobTitle}</TableCell>
                      <TableCell align="left">{row.officeLocation}</TableCell>
                      <TableCell align="left">
                        {row.assigned ? (
                          <div>
                            {isItemSelected ? (
                              // {row.assigned}
                              <div>Keep</div>
                            ) : (
                              <div>Remove</div>
                            )}
                          </div>
                        ) : (
                          <div>
                            {isItemSelected ? (
                              // {row.assigned}
                              <div>Add</div>
                            ) : (
                              <div> - </div>
                            )}
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" />
      </Paper>

      <Paper sx={{ mb: 2, p: 2 }}>
        <FormControlLabel
          control={<Switch checked={rawData} onChange={handleChangeRaw} />}
          label="Show Raw User Data"
        />

        <Container
          sx={{
            display: rawData ? "block" : "none",
          }}
        >
          <pre>{JSON.stringify(props.data, null, 2)}</pre>
        </Container>
      </Paper>
      {/* </Box> */}
    </ThemeProvider>
  );
};
