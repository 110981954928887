const REACT_APP_AZFUNCTIONENDPOINTS= "https://subscriptionmanagerfunctions.azurewebsites.net"


// REACT_APP_REACT_APP_AZFUNCTIONENDPOINTS

//function to call the listOperations webhook api
export async function getSubscriptionFromDB(accountData: any) {
  // let marketplacetoken = props.marketplacetoken;
  const validation: any = await fetch(`${REACT_APP_AZFUNCTIONENDPOINTS}/api/operations`, {
    // const validation: any = await fetch("http://localhost:7071/api/operations", {

    
    method: "POST",
    headers: {
      "Content-Type": "application/vnd.api+json",
      Accept: "application/vnd.api+json",

      task: "getSubfromDB",
    },
    body: JSON.stringify(accountData),
  });
  console.log('365APPS ~ getSubscriptionFromDB ~ validation', validation);
  
  if (validation.status === 200) {
    
     
    return await validation.json();
    
  } else {
    console.log("365APPS ~ resolveAPI ~ validation Error", validation);

    return;
  }

  // //function to parse the response body
}

//function to call the listOperations webhook api
export async function getSubscription(subsData: any) {
  // let marketplacetoken = props.marketplacetoken;

  let subscriptionID: string = subsData ? subsData.subscriptionId : "";

  if (subscriptionID) {
    const validation: any = await fetch(`${REACT_APP_AZFUNCTIONENDPOINTS}/api/operations`, {
      // const validation: any = await fetch("http://localhost:7071/api/operations", {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.api+json",
        Accept: "application/vnd.api+json",
        // "x-ms-marketplace-token": marketplacetoken,
        subscriptionID: subscriptionID,
        task: "getSub",
      },
    });
    if (validation.status === 200) {
      return await validation.json();
    } else {
      console.log("365APPS ~ resolveAPI ~ validation Error", validation);

      return;
    }
  }
  // //function to parse the response body
}

//function to call the listOperations webhook api
export async function storeSubscriptionInDB(storagedata: any) {

  if (storagedata) {
    const validation: any = await fetch(`${REACT_APP_AZFUNCTIONENDPOINTS}/api/operations`, {
      // const validation: any = await fetch("http://localhost:7071/api/operations", {
      method: "POST",
      headers: {
        "Content-Type": "application/vnd.api+json",
        Accept: "application/vnd.api+json",

        task: "storeSubinDB",
      },
      body: JSON.stringify(storagedata),
    });
    if (validation.status === 200) {
      let result = validation;

      return result;
    } else {
      console.log("365APPS ~ resolveAPI ~ validation Error", validation);

      return;
    }
  }
}

//function to call the RESOLVE  subscription webhook api
export async function resolveAPI(marketplacetoken: string) {

  const validation: any = await fetch(`${REACT_APP_AZFUNCTIONENDPOINTS}/api/resolve`, {
    // const validation: any = await fetch("http://localhost:7071/api/resolve", {
    method: "GET",
    headers: {
      "Content-Type": "application/vnd.api+json",
      Accept: "application/vnd.api+json",
      "x-ms-marketplace-token": marketplacetoken,
    },
  });

  if (validation.status === 200) {
    return await validation.json();
  } else {
    return;
  }
}

export async function activateSubscription(subsData: any, marketplacetoken: any) {
  try {

    let subscriptionID: string = subsData ? subsData.subscriptionId : "";

    let planId: string = subsData ? subsData.subscriptiondata.planId : "";

    let quantity: any = subsData ? subsData.subscriptiondata.quantity : "";


    const Activation: any = await fetch(`${REACT_APP_AZFUNCTIONENDPOINTS}/api/activate`, {
      // const Activation: any = await fetch("http://localhost:7071/api/activate", {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.api+json",
        Accept: "application/vnd.api+json",
        "x-ms-marketplace-token": marketplacetoken,
        subscriptionID: subscriptionID,
        planId: planId,
        quantity: quantity,
      },
    });
    if (Activation.status === 200) {
      // return await Activation.json();
      return "OK";
    } else {
      console.log("365APPS ~ activateSubscription ~ Activation Error", Activation);

    }
  } catch (error) {
    console.log("365APPS ~ activateSubscription ~ error", error);

    return "Error";
  }
}

//function to call the incomingwebhook api to notify of new subscription
export async function notifyActivationSubscription(subsData: any) {
  let subscriptionID: string = subsData ? subsData.subscriptionId : "";

  let data: any = {};

  data.subscription = subsData;

  data.action = "Activation";

  const notify: any = await fetch(`${REACT_APP_AZFUNCTIONENDPOINTS}/api/incomingwebhook`, {
    // const notify: any = await fetch("http://localhost:7071/api/incomingwebhook", {
    method: "POST",
    headers: {
      "Content-Type": "application/vnd.api+json",
      Accept: "application/vnd.api+json",
    },
    body: JSON.stringify(data),
  });
  if (notify.status === 200) {
    return;
  } else {
    console.log("365APPS ~ incomingWebhook ~ notify Error", notify);

    return;
  }
}


//function to call the incomingwebhook api to notify of new subscription
export async function notifyNewSubscription(subsData: any) {
  let subscriptionID: string = subsData ? subsData.subscriptionId : "";

  let data: any = {};

  data.subscription = subsData;

  data.action = "NewSubscriptionNotification";

  const notify: any = await fetch(`${REACT_APP_AZFUNCTIONENDPOINTS}/api/incomingwebhook`, {
    // const notify: any = await fetch("http://localhost:7071/api/incomingwebhook", {
    method: "POST",
    headers: {
      "Content-Type": "application/vnd.api+json",
      Accept: "application/vnd.api+json",
    },
    body: JSON.stringify(data),
  });
  if (notify.status === 200) {
    return;
  } else {
    console.log("365APPS ~ incomingWebhook ~ notify Error", notify);

    return;
  }
}

//function to call the incomingwebhook api to notify of new subscription
export async function notifyCancellation(subsData: any) {
  let subscriptionID: string = subsData ? subsData.subscriptionId : "";


  let data: any = {};

  data.subscription = subsData;

  data.action = "Unsubscribe";

  const notify: any = await fetch(`${REACT_APP_AZFUNCTIONENDPOINTS}/api/incomingwebhook`, {
    // const notify: any = await fetch("http://localhost:7071/api/incomingwebhook", {
    method: "POST",
    headers: {
      "Content-Type": "application/vnd.api+json",
      Accept: "application/vnd.api+json",
    },
    body: JSON.stringify(data),
  });
  if (notify.status === 200) {
    return;
  } else {
    console.log("365APPS ~ incomingWebhook ~ notify Error", notify);

    return;
  }
}

//function to call the incomingwebhook api to notify of new subscription
export async function notifyActivationFailure(subsData: any) {
  let subscriptionID: string = subsData ? subsData.subscriptionId : "";


  let data: any = {};

  data.subscription = subsData;

  data.action = "ActivationFailure";

  const notify: any = await fetch(`${REACT_APP_AZFUNCTIONENDPOINTS}/api/incomingwebhook`, {
    // const notify: any = await fetch("http://localhost:7071/api/incomingwebhook", {
    method: "POST",
    headers: {
      "Content-Type": "application/vnd.api+json",
      Accept: "application/vnd.api+json",
    },
    body: JSON.stringify(data),
  });
  if (notify.status === 200) {
    return;
  } else {
    console.log("365APPS ~ incomingWebhook ~ notify Error", notify);

    return;
  }
}

//function to call the incomingwebhook api to notify of new subscription
export async function notifyOperationFailure(subsData: any) {
  let subscriptionID: string = subsData ? subsData.subscriptionId : "";


  let data: any = {};

  data.subscription = subsData;

  data.action = "OperationFailure";

  const notify: any = await fetch(`${REACT_APP_AZFUNCTIONENDPOINTS}/api/incomingwebhook`, {
    // const notify: any = await fetch("http://localhost:7071/api/incomingwebhook", {
    method: "POST",
    headers: {
      "Content-Type": "application/vnd.api+json",
      Accept: "application/vnd.api+json",
    },
    body: JSON.stringify(data),
  });
  if (notify.status === 200) {
    return;
  } else {
    console.log("365APPS ~ incomingWebhook ~ notify Error", notify);

    return;
  }
}

export async function UpdateSubscriptionPlan(subsData: any, newplan: any) {
  console.log('365APPS ~ UpdateSubscriptionPlan ~ subsData', subsData);
  let subscriptionID: string = subsData ? subsData.subscriptionId : "";

  let plan = {
    planId: newplan,
  };

  const Update: any = await fetch(`${REACT_APP_AZFUNCTIONENDPOINTS}/api/operations`, {
    // const Update: any = await fetch("http://localhost:7071/api/operations", {
    method: "POST",
    headers: {
      "Content-Type": "application/vnd.api+json",
      Accept: "application/vnd.api+json",
      subscriptionID: subscriptionID,
      // planId: planId,
      task: "changePlan",
    },
    body: JSON.stringify(plan),
  });
  console.log('365APPS ~ UpdateSubscriptionPlan ~ Update', Update);
  if (Update.status === 200) {
    
    console.log('365APPS ~ UpdateSubscriptionPlan ~ Update.status = 200', Update);

    return Update;
  } else {
    console.log("365APPS ~ activateSubscription ~ Update Error", Update);
    return null;
  }
}

export async function UpdateSubscriptionQuantity(subsData: any, Quantity: any) {
  let subscriptionID: string = subsData ? subsData.subscriptionId : "";

  let quantity = {
    quantity: Quantity,
  };

  const Update: any = await fetch(`${REACT_APP_AZFUNCTIONENDPOINTS}/api/operations`, {
    // const Update: any = await fetch("http://localhost:7071/api/operations", {
    method: "POST",
    headers: {
      "Content-Type": "application/vnd.api+json",
      Accept: "application/vnd.api+json",
      subscriptionID: subscriptionID,
      // planId: planId,
      task: "changeQuantity",
    },
    body: JSON.stringify(quantity),
  });
   console.log('365APPS ~ UpdateSubscriptionQuantity ~ Update', Update);

   listOperations(subsData);

    let result = await Update.json();
    console.log('365APPS ~ UpdateSubscriptionQuantity ~ result ', result );
    
    if (result.status === 200) {
      return result;
      // return Update.json();
    } else {
      console.log("365APPS ~ UpdateSubscriptionQuantity ~ Update Error", result);
      return result;
    }
  
}

//update cosmos db with subscription data

export async function storeSubscriptionCosmos(resolvedSubinfo: any) {
  let subscriptionID: string = resolvedSubinfo ? resolvedSubinfo.id : "";

  resolvedSubinfo.action = "NewSubscription";

  let payload: any = JSON.stringify(resolvedSubinfo);

  if (subscriptionID) {
    const validation: any = await fetch(`${REACT_APP_AZFUNCTIONENDPOINTS}/api/incomingWebHook`, {
      // const validation: any = await fetch("http://localhost:7071/api/incomingWebHook", {
      method: "POST",
      headers: {
        "Content-Type": "application/vnd.api+json",
        Accept: "application/vnd.api+json",
      },
      body: payload,
    });
    if (validation.status === 200) {
      return validation.json();
    } else {
      console.log("365APPS ~ resolveAPI ~ validation Error", validation);

      return;
    }
  }
  // //function to parse the response body
}

//function to call the listOperations webhook api
export async function listOperations(subsData: any) {
  // let marketplacetoken = props.marketplacetoken;

  let subscriptionID: string = subsData ? subsData.subscriptionId : "";

  if (subscriptionID) {
    const validation: any = await fetch(`${REACT_APP_AZFUNCTIONENDPOINTS}/api/operations`, {
      // const validation: any = await fetch("http://localhost:7071/api/operations", {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.api+json",
        Accept: "application/vnd.api+json",
        // "x-ms-marketplace-token": marketplacetoken,
        subscriptionID: subscriptionID,
        task: "listOPs",
      },
    });
    // console.log("365APPS ~ listOperations ~ validation", validation);
    if (validation.status === 200) {
     let tezt =  JSON.stringify(validation)
     console.log('365APPS ~ listOperations ~ tezt', tezt);

      return await validation.json();
    } else {
      console.log("365APPS ~ resolveAPI ~ validation Error", validation);

      return;
    }
  }
  // //function to parse the response body
}

//function to call the listOperations webhook api
export async function UpdateOperation(subsData: any, OperationId: any) {
  // let marketplacetoken = props.marketplacetoken;

  let subscriptionID: string = subsData ? subsData.subscriptionId : "";

  if (subscriptionID) {
    const validation: any = await fetch(`${REACT_APP_AZFUNCTIONENDPOINTS}/api/operations`, {
      // const validation: any = await fetch("http://localhost:7071/api/operations", {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.api+json",
        Accept: "application/vnd.api+json",
        // "x-ms-marketplace-token": marketplacetoken,
        subscriptionID: subscriptionID,
        operation: OperationId,
        status: "Success",
        task: "updateOpStatus",
      },
    });
    if (validation.status === 200) {
      return await validation.json();
    } else {
      console.log("365APPS ~ resolveAPI ~ validation Error", validation);

      return;
    }
  }
  // //function to parse the response body
}
//"http://localhost:7071/"
//  `${REACT_APP_AZFUNCTIONENDPOINTS}/api/operations
//  `${REACT_APP_AZFUNCTIONENDPOINTS}/api/activate
//  `${REACT_APP_AZFUNCTIONENDPOINTS}/api/resolve
//  `${REACT_APP_AZFUNCTIONENDPOINTS}/api/updateplan

export async function listPlans(subsData: any) {
  console.log('365APPS ~ listPlans ~ subsData', subsData);
  // let subscriptionID: string = subsData ? subsData.id : "";
  let subscriptionID: string = subsData ? subsData.id : "";

  const plans: any = await fetch(`${REACT_APP_AZFUNCTIONENDPOINTS}/api/operations`, {
    // const plans: any = await fetch("http://localhost:7071/api/operations", {
    method: "GET",
    headers: {
      "Content-Type": "application/vnd.api+json",
      Accept: "application/vnd.api+json",
      subscriptionID: subscriptionID,
      task: "listPlans",
    },
  });
  if (plans.status === 200) {
    let data = await plans.json();


    return data;
  } else {
    console.log("365APPS ~ listPlans ~ Error", plans);

    return;
  }
}

//function to call the listOperations webhook api
export async function cancelSubscriptionAPI(subsData: any) {
  // let marketplacetoken = props.marketplacetoken;

  let subscriptionID: string = subsData ? subsData.id : "";

  if (subscriptionID) {
    const cancelTask: any = await fetch(`${REACT_APP_AZFUNCTIONENDPOINTS}/api/operations`, {
      // const validation: any = await fetch("http://localhost:7071/api/operations", {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.api+json",
        Accept: "application/vnd.api+json",
        // "x-ms-marketplace-token": marketplacetoken,
        subscriptionID: subscriptionID,
        task: "cancelSub",
      },
    });


    if (cancelTask.status === 200) {
      //TODO: handle the response properly WHY DOES THIS ERROR OUT???????

   
      let data = await cancelTask


      return data;

    } else {
      console.log("365APPS ~ resolveAPI ~ validation Error", cancelTask);

      return;
    }
  }
  // //function to parse the response body
}


//function to call the incomingwebhook api to notify of new subscription
export async function notifyUserChanges(subsData:any,userData: any) {
  
  let subscriptionID: string = subsData ? subsData.subscriptionId : "";

  let data: any = {};

  data.subscription = subsData;

  data.action = "notifyUserChanges";

  // let data: any = {};

  let payload: any = JSON.stringify(userData);
  data.payload = payload

  const notify: any = await fetch(`${REACT_APP_AZFUNCTIONENDPOINTS}/api/incomingwebhook`, {
    // const notify: any = await fetch("http://localhost:7071/api/incomingwebhook", {
    method: "POST",
    headers: {
      "Content-Type": "application/vnd.api+json",
      Accept: "application/vnd.api+json",
    },   
    body: JSON.stringify(data),
  });
  if (notify.status === 200) {
    return;
  } else {
    console.log("365APPS ~ KeygenWebhook ~ notify Error", notify);

    return;
  }
}