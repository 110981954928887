import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { ETable } from "../components/table";

import * as React from "react";
import { useState } from "react";
import { AttributionRounded } from "@mui/icons-material";
import { assignMachine, removeMachine } from "../provisioning";
import { Alert } from "@mui/material";
import { notifyUserChanges } from "./APIservices";

function descendingComparator(a: any, b: any, orderBy: any) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// function getComparator(order:any, orderBy:any) {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// // This method is created for cross-browser compatibility, if you don't
// // need to support IE11, you can use Array.prototype.sort() directly
// function stableSort(array:any, comparator:any) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "mail",
    numeric: true,
    disablePadding: false,
    label: "Email",
  },
];

export interface IUserTableProps {
  userData: any;
  assigned: any;
  licenseData: any;
  tid: any;
  licenseId: any;
  back: () => void;
  subsData: any;
}

// functional component to display user data in table
//

export const UserAssignments: React.FunctionComponent<IUserTableProps> = (
  props: React.PropsWithChildren<IUserTableProps>
) => {
  // console.log("365APPS ~ userTable ~ props", props);

  const [userData, setUserData] = React.useState<any>(props.userData);

  const [selected, setSelected] = React.useState<any>([]);

  const [selectedPreviously, setSelectedPreviously] = React.useState<any>([]);

  const [ready, setReady] = React.useState(false);

  const [lefttoassign, setlefttoassign] = React.useState(props.licenseData);

  const [licenseNumber, setLicenseNumber] = React.useState<any>(props.licenseData);

  const [rows, setRows] = React.useState<any>([]);

  const [loadingMessage, setloadingMessage] = React.useState<any>("");

  React.useEffect(() => {
    let localrows: any[] = [];
    let localassigned: any[] = [];

    if (userData) {
      console.log("365APPS ~ React.useEffect ~ userData", userData);
      userData.map((user: any, Index: any) => {
        let result = props.assigned.filter((machine: any) => machine.attributes.fingerprint === user.id)[0];

        // console.log("365APPS ~ props.userData.map ~ result", user.id, " found in ", result);

        let userRow = {
          key: user.id,
          Name: user.displayName,
          Email: user.mail ? user.mail : null,
          jobTitle: user.jobTitle,
          officeLocation: user.officeLocation,
          Index: Index,
          assigned: result ? "Yes" : null,
        };
        localrows.push(userRow);

        if (result) {
          localassigned.push(userRow);
        }
        result = null;
      });

      setRows(localrows);
      setSelectedPreviously(localassigned);

      setSelected(localassigned);
      setlefttoassign(licenseNumber - localassigned.length);

      console.log("365APPS ~ React.useEffect ~ lefttoassign", lefttoassign);
      console.log("365APPS ~ React.useEffect ~ selected", selected);
    }
  }, [props]);

  async function processUserDataAdds() {
    selected.forEach((element: any) => {
      setTimeout(async () => {
        let result = selectedPreviously.filter((row: any) => row.key === element.key)[0];

        if (!result) {
          setloadingMessage("Assigning..." + element.Name);
          let activationresponse: any = await assignMachine(props.tid, element, props.licenseId);
          console.log("365APPS ~ user ~ activationresponse", activationresponse);
          setTimeout(async () => {
            if (activationresponse.activated === true) {
              setloadingMessage("Assigning... " + element.Name + " Done");
            } else {
              setloadingMessage("Assigning... " + element.Name + "  - Already assigned");
            }
          }, 1500);
        }
      }, 2500);
    });
  }

  async function processUserDataRemovals() {
    selectedPreviously.forEach((element: any) => {
      setTimeout(async () => {
        let result = selected.filter((row: any) => row.key === element.key)[0];

        if (!result) {
          setloadingMessage("Removing..." + element.Name);
          await removeMachine(props.tid, element);
        }
      }, 2500);
    });
  }

  const saveDATA = async () => {
    try {
      setloadingMessage("Assigning...");

      console.table("selected", selected);
      console.table("selectedIndexes to remove", selectedPreviously);

      await processUserDataAdds().then(async () => {
        await processUserDataRemovals();
      });
      setTimeout(() => {
        notifyUserChanges(props.subsData, selected);
        setUserData(selected);
        setloadingMessage("Complete");
        props.back();
      }, 8000);
    } catch (error) {}
  };

  const onRowSelectionChange = (rowsSelected: any[]) => {
    setSelected(rowsSelected);
    setlefttoassign(licenseNumber - rowsSelected.length);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid item>
        <Paper sx={{  mb: 2, p:2}}>
          <Box
            sx={{
              p: 2,
              bgcolor: "background.default",
              display: "grid",
              gridTemplateColumns: { md: "1fr 1fr" },
              gap: 2,
            }}
          >
            <Typography variant="h4">Assign Licenses</Typography>
          </Box>
          <Box
            sx={{
              p: 2,
              bgcolor: "background.default",
              display: "grid",
              gridTemplateColumns: { md: "1fr 1fr" },
              gap: 2,
            }}
          >
            <Typography>To get started assign your purchased licenses</Typography>
          </Box>
        </Paper>
      </Grid>

      <Grid id="tablecontainer">
        <Paper sx={{ mb: 2, p: 2 }}>
          <Grid container spacing={1} direction="row">
            <Grid item xs={6}>
              <Grid container>
                <Grid item>
                  <Typography variant="h6">License Count: </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 600,
                      ml: 1,
                    }}
                  >
                    {licenseNumber ? licenseNumber : 0}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="h6">Available to assign: </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 600,
                      ml: 1,
                      color: lefttoassign > 0 ? "#333" : "red",
                    }}
                  >
                    {lefttoassign}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid
                  item
                  sx={{
                    m: 1,
                  }}
                >
                  <Button
                    sx={{
                      width: "150px",
                    }}
                    disabled={selected.length > 0 && lefttoassign > -1 && !ready ? false : true}
                    variant="contained"
                    onClick={() => setReady(true)}
                  >
                    Review
                  </Button>
                </Grid>

                <Grid
                  item
                  sx={{
                    m: 1,
                  }}
                >
                  <Button
                    sx={{
                      width: "150px",
                    }}
                    variant="outlined"
                    onClick={() => props.back()}
                  >
                    back
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {/* Status Messages */}
      <Grid item xs={12} md={12} lg={12} sx={{ mb: 2, mt: 2 }}>
        <Alert severity="info">
          Status:
          <strong>{loadingMessage}</strong>
        </Alert>
      </Grid>
      {!ready && (
        <Grid item xs={12} md={12} lg={12} id="tablehouse" gridColumn="span" sx={{ mb: 2, mt: 2}}>
          <ETable
            thechosenOnes={(data) => onRowSelectionChange(data)}
            data={rows}
            selectedIndexes={selectedPreviously}
          />
        </Grid>
      )}

      {ready && (
        <Grid container>
          <Grid item>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <Box
                sx={{
                  p: 2,
                  bgcolor: "background.default",
                  display: "grid",
                  gridTemplateColumns: { md: "1fr 1fr" },
                  gap: 2,
                }}
              >
                <Typography variant="h4">Review Assigned </Typography>
              </Box>
              <Box
                sx={{
                  p: 2,
                  bgcolor: "background.default",
                  display: "grid",
                  gridTemplateColumns: { md: "1fr 1fr" },
                  gap: 2,
                }}
              >
                <Typography>This can be changed later</Typography>
              </Box>
              <Box
                sx={{
                  p: 2,
                  justifyContent: "flex-end",
                  display: "grid",
                  gridTemplateColumns: { md: "1fr 1fr" },
                  gap: 2,
                }}
              >
                <Grid item>
                  <Button
                    sx={{
                      width: "150px",
                    }}
                    disabled={selected.length > 0 && lefttoassign > -1 ? false : true}
                    variant="contained"
                    onClick={() => saveDATA()}
                  >
                    Save
                  </Button>{" "}
                </Grid>
                <Grid item>
                  <Button
                    sx={{
                      width: "150px",
                    }}
                    variant="outlined"
                    disabled={ready ? false : true}
                    onClick={() => setReady(false)}
                  >
                    Back
                  </Button>{" "}
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                  >
                    {selected.map((user: any, index: any) => (
                      <Grid item key={"person" + index}>
                        {/* <Typography >{user.Name} </Typography> */}
                        <Typography key={"person" + index}>{user.Email} </Typography>
                       
                      </Grid>
                    ))}
                  </Box>{" "}
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};
