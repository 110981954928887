import "source-map-support/register";

import React, { useEffect, useState } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from "@azure/msal-react";
import posthog from "posthog-js";

import { InteractionType } from "@azure/msal-browser";

import { Dashboard } from "./components/DashBoard2";
import { PreAuthenticationPage } from "./components/PreAuthenticationPage";
import "./styles/App.css";
import { useLocalStorage } from "@penseapp/uselocalstorage";

import { msalInstance } from "./index";
 

export default function App() {
  // console.log("App");
  posthog.init("phc_yOkq0DaRq8hNSztfpdfzwqJyyia8q7GL0ynmuuKSWRm", { api_host: "https://app.posthog.com", enable_recording_console_log: true,
});

  // require("dotenv").config({ path: path.resolve(process.cwd(), env) });
  require("dotenv").config();
  // console.log("process.env ", process.env)

  const request = {
    scopes: ["User.Read", "User.Read.All"],
    prompt: "select_account",
  };
  const { login, result, error } = useMsalAuthentication(InteractionType.Popup, request);

  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();

  // let graphDataUsers: any = [];

  // const [graphDataUsers, setGraphDataUsers] = useState(null);

  const [settings, setSettings] = useLocalStorage(
    "CMSettings",
    {
      tid: "",
      displayName: "",
      email: "",
      userId: "",
      account: {},
    },
    5
  );

 

  const [appStoreToken, setappStoreToken] = useState("");



  // no token stored so fetch one
  useEffect(() => {
    async function go() {
      if (isAuthenticated) {
        accounts.forEach((account) => {
          console.log("365APPS ~ accounts.forEach ~ account", account);
          msalInstance.setActiveAccount(account);
          let accountName: any = account ? account.name : "";

        

          setSettings({
            tid: account.tenantId,
            displayName: accountName ? accountName : "",
            email: account.username,
            userId: account.localAccountId,
            account: account,
          });

          posthog.identify(account.localAccountId, {
            email: account.username,
          });

          posthog.people.set({ email: account.username });

        });

        // console.log("365APPS ~ App ~ isAuthenticated", isAuthenticated);

        // await graphUsers();
      }
    }
    go();
  }, [isAuthenticated]);

  // console.log('365APPS ~ useEffect ~ isAuthenticated', isAuthenticated);
  //when logged in get more data using the token

  //function to read the token from the url parameters
  async function getParameterByName() {
    let name = "token";
    //if the token is not null then set the appstore token
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    let token = decodeURIComponent(results[2].replace(/\+/g, " "));
    if (token) {
      setappStoreToken(token);

      // appStoreToken = token;
    } else {
      console.log("365APPS ~ No token");
    }
  }

  useEffect(() => {
    getParameterByName();
  }, []);

  //if anything errors
  // add error boundary
  useEffect(() => {
    if (error) {
      console.log("APP", error);
    }
  }, [error]);

  const DashBoardContent = () => {
    // // console.log("365APPS ~ App Loaded ~ DashBoardContent appStoreToken", appStoreToken);

    return (
      <div>
        {settings.tid.length > 0 ? (
          <div>
            <Dashboard accountData={settings} marketplacetoken={appStoreToken} />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  return (
    <div>
      <div
      //className="App"
      >
        <AuthenticatedTemplate>
          <DashBoardContent />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <PreAuthenticationPage></PreAuthenticationPage>
        </UnauthenticatedTemplate>
      </div>
    </div>
  );
}

// export default App;
