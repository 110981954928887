import { loginRequest2, graphConfig } from "./authConfig";
import { msalInstance } from "./index";

export async function callMsGraphGetUsers(accessToken, url = null) {
  if (!accessToken) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      console.log("Error(No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest2,
      account: account,
    });
    accessToken = response.accessToken;
  }

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  const requestUrl = url || graphConfig.graphUsers;

  try {
    const response = await fetch(requestUrl, options);
    const data = await response.json();

    if (data["@odata.nextLink"]) {
      const nextData = await callMsGraphGetUsers(accessToken, data["@odata.nextLink"]);
      data.value.push(...nextData.value);
    }

    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
}
