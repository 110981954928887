/*


  tenant id = license key
  userObject = user id

  starts with getlicense

  1.validateTidLicense
 
  validate if the license exists 
  if not create a trial license

  2.getlicenseToken
  fetch a token for the license

  3. expiredCheck
  check whether license expiry date is too old

  4. expiry
  get the actual number of days left

  5. retrieve policy
  get the policy for the license which tells us the license type

  6. checkmachine
  check if the userObject is activated on the license

  7. activatelicense
  activate the license for the userObject
  uses the token from step 2
  DOES NOT WORK




  */

const accntid = "1cf9f5e0-9258-45a1-903e-6f0c8eadc436";
const KEYGEN_ACCOUNT_ID = "1cf9f5e0-9258-45a1-903e-6f0c8eadc436";
const KEYGEN_ACTIVATION_TOKEN2 = "prod-7704b1178db22adf358e5480f692e4e672a5e808e74b3485d69e6e195252831dv3";
let licenseId = "";
let licensetoken = "";

export async function getLicense(Tid: any, userObject: any) {
  // public async getLicense(Tid, userObject) {
  // console.log("365APPS ~ licensing ~ Checking license with TID = ", Tid, "and userObject = ", userObject);

  try {
    if (Tid && userObject) {
      //check if the license exists and if not create a new one
      let license: any = await validateTidLicense(Tid, userObject); //stores initial email in metadata
      // console.log("365APPS licensing Validation response: ", license);

      let token = await fetchActivationToken(Tid);
      // console.log("365APPS ~ getLicense ~ token", token);

      //need to create a license token via azure function
      licensetoken = token.result.data.attributes.token;
      // console.log("365APPS ~ getLicense ~ this.licensetoken", this.licensetoken);

      if (licensetoken != null) {
        // console.log("365APPS licensing license maxMachines", res.resp.attributes.maxMachines);
        let expiredCheckValue: any = await expiredCheck(license.data.attributes.expiry);

        let expiryValue: any = await expiry(license.data.attributes.expiry);

        let licenseType: any = await retrievePolicy(license.data.relationships.policy.data.id);
        // console.log("365APPS ~ getLicense ~ licenseType", licenseType);

        let machineData: any = {};
        if (license.data.relationships.machines.meta.count > 0) {
          let link = license.data.relationships.machines.links.related;
        
          machineData = await retrieveMachines(link);
        }

    
    

        let obj = {
          message:
            "License name:" +
            license.data.attributes.name +
            " Expiry date:" +
            license.data.attributes.expiry +
            ". The user is active",
          status: license.data.attributes.status,
          expiry: expiry,
          expiredCheck: expiredCheck,

          licenseType: licenseType.resp.attributes.name,
          license:license,
          machines: machineData.data,
        };

        expiredCheckValue = null;
        expiryValue = null;

        return obj;
      } else {
        console.log("365APPS ~ getLicense ~ this.licensetoken is null");
        return {
          meta: "License token failure",
          activated: false,
          // machine,
        };
      }
    }
  } catch (err) {
    console.log("365APPS  ~ licensing ~ Error", err);

    let obj = {
      message: "License validation failed",
      status: "ERROR",
      expiry: 999,
      expiredCheck: false,
      licenseType: "Error",
    };

    console.log("365APPS ~ getLicense ~ obj", obj);
    return obj;
  }
}

export async function retrieveMachines(link: any) {
  // console.log("365APPS ~ licensing ~ retrieveMachines with Tid = ", Tid, "and userObject = ", userObject);
  const validation = await fetch(
    `https://api.keygen.sh/${link}?limit=100`,

    {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.api+json",
        Accept: "application/vnd.api+json",
        Authorization: `Bearer ${licensetoken}`,
      },
    }
  );

  const { meta, data } = await validation.json();
  // console.log("365APPS licensing ~ license CheckThenActivateMachine ~  meta", meta);
  // console.log("365APPS licensing ~ license CheckThenActivateMachine ~   data", data);

  if (!data) {
    // console.log("365APPS ~ licensing ~  user license not found");
    return {
      meta: meta, //.constant + " License does not exist",
      activated: false,
      // machine,
    };
  } else {
    return {
      meta: meta,
      data: data,
      activated: true,
      // machine,
    };
  }
}

export async function checkMachine(Tid: any, userObject: any) {
  // console.log("365APPS ~ licensing ~ checking  user license ~", userObject.userId);

  // Validate the license key before activation, so we can be sure it supports
  // another machine. Notice that this validation is scoped to the current
  // machine via its fingerprint - this ensures that license activation is
  // not performed for machines that are already activated.

  //https://api.keygen.sh/v1/accounts/{ACCOUNT}/machines/eef41cf5-f32e-4dab-a867-b9738d87285b

  const validation = await fetch(
    `https://api.keygen.sh/v1/accounts/${accntid}/machines/${userObject.userId}`,

    {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.api+json",
        Accept: "application/vnd.api+json",
        Authorization: `Bearer ${licensetoken}`,
      },
    }
  );

  const { meta, data } = await validation.json();
  // console.log("365APPS licensing ~ license CheckThenActivateMachine ~  meta", meta);
  // console.log("365APPS licensing ~ license CheckThenActivateMachine ~   data", data);

  if (!data) {
    // console.log("365APPS ~ licensing ~  user license not found");
    return {
      meta: meta, //.constant + " License does not exist",
      activated: false,
      // machine,
    };
  } else {
    return {
      meta: meta,
      data: data,
      activated: true,
      // machine,
    };
  }
}

export async function ActivateMachine(Tid: any, userObject: any,licenseID:any) {
  // console.log("365APPS ~ ActivateMachine ~ userObject", userObject, " licenseID ", licenseID);

  let token = await fetchActivationToken(Tid);
  // console.log("365APPS ~ getLicense ~ token", token);

  //need to create a license token via azure function
  licensetoken = token.result.data.attributes.token;

  if (licensetoken) {
    const activation = await fetch(`https://api.keygen.sh/v1/accounts/${accntid}/machines`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${KEYGEN_ACTIVATION_TOKEN2}`,
        
        // Authorization: `Bearer ${licensetoken}`,
        "Content-Type": "application/vnd.api+json",
        Accept: "application/vnd.api+json",
        // "Authorization": "Bearer {TOKEN}"
      },
      body: JSON.stringify({
        data: {
          type: "machines",
          attributes: {
            fingerprint: userObject.userId,
            name: userObject.email,
            metadata: {
              Tid: Tid,
              userObject: userObject.userId ? userObject.userId : "",
              userPrincipalName: userObject.email ? userObject.email : "",
              // teamSiteDomain: userObject.teamSiteDomain ? userObject.teamSiteDomain : "",
            },
          },
          relationships: {
            license: {
              data: {
                type: "licenses",
                id: licenseID,
              },
            },
          },
        },
      }),
    });

    const { meta, data, errors } = await activation.json();

    if (errors) {
      console.log(errors[0].code, errors[0].detail);

      let metaData = errors[0].code + errors[0].detail;

      // throw new Error(JSON.stringify(errors, null, 2));
      return {
        meta: metaData,
        data: data,
        activated: false,
        // machine,
      };
    } else {
      // We're caching the activation response locally so that we can perform
      // a machine deactivation without hitting the network to query for the
      // await setMachineCache(machine)
      // current machine's ID (which is different from its fingerprint).
      // // console.log("365APPS licensing Keygen ~ ~ machine", machine);

      // All is good - the machine was successfully activated.

      return {
        meta: meta,
        data: data,
        activated: true,
      };
    }
  }
}

export async function DeActivateMachine(Tid: any, userId: any) {
  
  let token = await fetchActivationToken(Tid);
  // console.log("365APPS ~ getLicense ~ token", token);

  //need to create a license token via azure function
  licensetoken = token.result.data.attributes.token;

  try{

    const deactivation = await fetch(`https://api.keygen.sh/v1/accounts/${accntid}/machines/${userId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/vnd.api+json",
        Accept: "application/vnd.api+json",
        Authorization: `Bearer ${licensetoken}`,
      },
    });
    
    return "OK"
  }
  catch(err){
    console.log("365APPS ~ licensing ~ DeActivateMachine ~ err", err);
    return "Error"
  }  
}

export async function retrievePolicy(policyID: any) {
  const validation = await fetch(`https://api.keygen.sh/v1/accounts/${accntid}/policies/${policyID}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${KEYGEN_ACTIVATION_TOKEN2}`,
      Accept: "application/vnd.api+json",
    },
  });
  const { data: resp, errors } = await validation.json();
  if (!errors) {
    // console.log("365APPS ~ licensing retrieved policy", resp);

    return {
      resp,
    };
  } else {
    // console.log("365APPS licensing  ~ policy fetch ~ errors", errors);
  }
}

//check for a license
//uses tenantID as the keygen key
export async function validateTidLicense(Tid: any, userObject: any) {
  const validation = await fetch(`https://api.keygen.sh/v1/accounts/${accntid}/licenses/${Tid}/actions/validate`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${KEYGEN_ACTIVATION_TOKEN2}`,

      Accept: "application/vnd.api+json",
    },
    body: JSON.stringify({
      meta: {
        scope: { fingerprint: userObject.userId }, //check whether /userObject machine exists
      },
    }),
  });

  const { data, errors } = await validation.json();

  if (!errors) {
    // console.log("365APPS ~ licensing ~ License found for this tenant", Tid);
    

    licenseId = data.id;

    // leave the function if license found

    return {
      existing: true,
      data,
    };
  } else {
    if (errors[0].title == "Not found") {
      // let token = await this.getlicenseToken(Tid);

      // console.log("365APPS ~ licensing ~ License not found for this tenant");
      //if the license is not found, create a new license
      //using the Tid as the key
      //and the customerInfo as the customer id

      //if creating new then always use the policyID for a trial license
      // console.log("365APPS licensing ~ Creating Trial ~ Tid", Tid, " & userObject", userObject);

      const createTrial = await fetch(`https://api.keygen.sh/v1/accounts/${accntid}/licenses`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${KEYGEN_ACTIVATION_TOKEN2}`,
          "Content-Type": "application/vnd.api+json",

          Accept: "application/vnd.api+json",
        },
        body: JSON.stringify({
          data: {
            type: "licenses",
            attributes: {
              key: Tid,
              name: "ContactsManager " + Tid,
              metadata: {
                Tid: Tid,
                userObject: userObject.userId ? userObject.userId : "",
                userPrincipalName: userObject.email ? userObject.email : "",
                teamSiteDomain: userObject.teamSiteDomain ? userObject.teamSiteDomain : "",
              },
            },
            relationships: {
              policy: {
                data: {
                  type: "policies",
                  id: "b3e6d277-da34-4427-a1af-ef134d407f88",
                  // "3cf55cf3-1a3b-4e86-97ff-52dd72bd7d28",
                },
              },
            },
          },
        }),
      });

      const { data, errors: err } = await createTrial.json();

      if (data) {
        licenseId = data.id;
        return {
          existing: "New",
          data,
        };
      }
      if (err) {
        // console.log("365APPS licensing Keygen ~ ~ errors", errors);
      }
    }
  }
}

export async function getProductToken(Tid: any) {
  // const getToken = async (LicenseID) => {
  let bearer: any; //= await this.getlicenseToken(Tid);
  if (bearer) {
    const getToken = await fetch(
      `https://api.keygen.sh/v1/accounts/${accntid}/products/1df5c219-819e-4dd9-bd78-02595e5fda0e/tokens`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/vnd.api+json",
          Accept: "application/vnd.api+json",
          // Authorization: `Bearer ${this.KEYGEN_ACTIVATION_TOKEN2}`,
          Authorization: `Bearer ${bearer}`,
        },
      }
    );
    const { data: resp, errors: err } = await getToken.json();

    if (resp) {
      return resp.attributes.token;
    }
    if (err) {
      return null;
    }
  }
  return null;
}

export async function fetchActivationToken(Tid: any) {
  // console.log('365APPS ~ fetchActivationToken ~ fetchActivationToken for : ', Tid);
  const getToken = await fetch("https://subscriptionmanagerfunctions.azurewebsites.net/api/keygentoken", {
    // const getToken = await fetch(" http://localhost:7071/api/KeygenToken", {
    method: "POST",
    headers: {
      //   "Content-Type": "application/vnd.api+json",
      //   Accept: "application/vnd.api+json",
    },
    body: JSON.stringify({
      data: {
        licenseID: Tid,
      },
    }),
  });

  const resp = await getToken;

  let results: any = resp.json();

  if (results) {
    return results;
  }
}

//check whether license expiry date is too old
export async function expiredCheck(input: any) {
  var inputDate = new Date(input);

  // Get today's date
  var todaysDate = new Date();

  // call setHours to take the time out of the comparison
  if (inputDate.setHours(0, 0, 0, 0) < todaysDate.setHours(0, 0, 0, 0)) {
    return "expired";
  } else {
    return "OK";
  }
}

//return the time left on the license
export async function expiry(input: any) {
  var inputDate = new Date(input);

  // Get today's date
  var todaysDate = new Date();

  let timeInMilisec: number = inputDate.getTime() - todaysDate.getTime();

  let daysBetweenDates: number = Math.ceil(timeInMilisec / (1000 * 60 * 60 * 24));

  return daysBetweenDates;
}
