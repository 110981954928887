import dotenv from "dotenv";

import { ErrorBoundary } from "react-error-boundary";

import { useIsAuthenticated } from "@azure/msal-react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MenuIcon from "@mui/icons-material/Menu";
import PeopleIcon from "@mui/icons-material/People";
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slider,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import Paper from "@mui/material/Paper";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useLocalStorage } from "@penseapp/uselocalstorage";
import * as React from "react";
import { checkKeygenLicense } from "../provisioning";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import { UserAssignments } from "./UserAssignments";
import { AccountSubscriptionData, SubscriptionData } from "./SubsModel";
import { Plans } from "./PlansModel";
import { callMsGraphGetUsers } from "../callMsGraph";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import {
  resolveAPI,
  activateSubscription,
  getSubscriptionFromDB,
  notifyActivationFailure,
  notifyCancellation,
  notifyNewSubscription,
  notifyActivationSubscription,
  storeSubscriptionInDB,
  UpdateSubscriptionPlan,
  cancelSubscriptionAPI,
  getSubscription,
  listPlans,
  UpdateSubscriptionQuantity,
  listOperations,
  UpdateOperation,
} from "./APIservices";
import { AccountEntity } from "@azure/msal-browser";
import { InsertDriveFileOutlined } from "@mui/icons-material";

const addchatwidget = () => {
  const script = document.createElement("script");
  script.src = "//js.hs-scripts.com/7636048.js";
  script.async = true;
  document.body.appendChild(script);
};
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
  // @ts-ignore
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const mdTheme = createTheme({
  palette: {
    primary: {
      light: "#ff7097",
      main: "#fb3569",
      dark: "#c2003f",
      contrastText: "#fff",
    },
    secondary: {
      light: "#73e8ff",
      main: "#29b6f6",
      dark: "#0086c3",
      contrastText: "#fff",
    },
  },
});

const DashboardContent = (props: any) => {
  dotenv.config();

  // console.log("DashboardContent process.env", process.env);
  // console.log("DashboardContent REACT_APP_AZFUNCTIONENDPOINTS", `${process.env.REACT_APP_AZFUNCTIONENDPOINTS}`);

  const [open, setOpen] = React.useState(false);

  const isAuthenticated = useIsAuthenticated();

  const [selectedView, setselectedView] = React.useState("dashboard");

  const [assigned, setassigned] = React.useState([]);

  const [loadingMessage, setloadingMessage] = React.useState<any>("");

  const [graphDataUsers, setGraphDataUsers] = React.useState<any>();

  const [licenseData, setlicenseData] = useLocalStorage(
    "licenseData",
    {
      data: {},
      // id: "",
      // expiry: "",
      // status: "",
      Assigned: 0,
      // PlanId: "",
      // notes: "",
    },
    60 * 24 * 7
  );

  const [subsData, setsubsData] = React.useState<AccountSubscriptionData>(); // subscription data;

  const [plansData, setplansData] = React.useState([]); //availble plans

  const [operations, setoperations] = React.useState([]); // operations

  const [runOne, setrunOne] = React.useState(false); // operations

  const [activeSub, setactiveSub] = React.useState(true); // active subscription enables buttons

  const [availablesub, setavailablesub] = React.useState(true);

  /*
    ORDER OF OPERATIONS

    on initial load resolve the token to fetch the subscription data from MS SAAS api

    if resolved OK provision a license with keygen

    store the subscription data in the keygen metadata
    and a secondary store

    create a license with keygen

    store that data too.

    */

  const graphUsers = async () => {
    //Used to ensure the graph can be called to get the list of users to assign licenses to
    try {
      let url;
      url = "/users?$count=true&$top=" + 999 + "";
      let data = await callMsGraphGetUsers();
      setGraphDataUsers(data.value);
      setloadingMessage("OK")
      // graphDataUsers = data.value;
    } catch (error) {
      setloadingMessage("Error fetching users. Please sign out and sign in again. If the problem persists please contact support@365apps.io");
      console.log("~ error", error);
    } finally {

    }
  };

  //run check function on component mount
  React.useEffect(() => {
    async function retrieval() {
      setloadingMessage("Checking Subscription Information...");

      if (props.marketplacetoken) {
        if (!runOne) {
          setrunOne(true);

          await resolve();
          await graphUsers();
        }
      } else {
        if (!runOne) {
          setrunOne(true);

          await resolvefromDB();
          await graphUsers();
        }
      }
    }

    retrieval();

    return () => {
      // perform any cleanup actions here
      console.log("Cleanup function called");
    };

  }, [props.accountData]);

  /**
   * Resolves the subscription data form the database
   *
   * @returns sets loading message and subsdata, enables buttons
   *
   */

  async function resolvefromDB() {
    let storedSubData: any;

    if (!runOne) {
      storedSubData = await getSubscriptionFromDB(props.accountData);
      console.log("365APPS ~ resolvefromDB ~ storedSubData", storedSubData);
    }

    if (storedSubData) {
      let status = storedSubData.subscriptiondata.subscription.saasSubscriptionStatus;

      console.log("365APPS ~ Subscription STATUS", status);

      setsubsData(storedSubData);

      licensingservice();
      if (status === "Subscribed") {
        setactiveSub(true);
        setavailablesub(false);
        setloadingMessage("Subscription Active");
        setTimeout(async () => {
          fetchSubscription(storedSubData);
          fetchPlans(storedSubData);
        }, 3000);
      }
      if (status === "Suspended") {
        setactiveSub(true); //disabled
        setavailablesub(false); //disabled

        setloadingMessage("Subscription suspended. Please navigate to the M365 Admin centre to reactivate");
      }
      if (status === "Unsubscribed") {
        setactiveSub(true); //disabled
        setavailablesub(true); //disabled
        setloadingMessage("Subscription cancelled");
      }
      if (status === "PendingFulfillmentStart") {
        if (!props.marketplacetoken) {
          //setactiveSub(false); //not disabled
          setloadingMessage(
            "Subscription pending fulfillment. Please fist navigate to the M365 Admin centre and choose Setup Account"
          );
        } else {
          setactiveSub(false); //not disabled
          setloadingMessage("Activation Required - please activate using the button above");
        }
      }
    } else {
      setloadingMessage(
        "Subscription pending fulfillment. Please navigate to the M365 Admin centre  and choose Setup Account"
      );
    }
  }

  //function to call the resolve api
  // and set data based upon the subscription

  async function resolve() {
    if (props.marketplacetoken) {
      setloadingMessage("Loading Subscription Information...");

      let info: any = await resolveAPI(props.marketplacetoken);
      console.log("365APPS ~ resolve ~ info", info.statusCode);

      if(info.statusCode === 400) {
        let me = JSON.parse(info.response.body);
        console.log("365APPS ~ Token ", me);
        if (me.Message) {
          setloadingMessage("ERROR Fetching subscription - " + me.Message);
        } else {
          setloadingMessage("ERROR Fetching subscription  - " + me.message);
        }
      }else{

      setTimeout(async () => {
        // .then((info:any) => {
        if (info) {
          let status = info.subscription.saasSubscriptionStatus;
          console.log("365APPS ~ //.then ~ info.subscription", info.subscription);
          console.log("365APPS ~ SaaS STATUS", status);

          let storagedata = {
            id: info.subscription.id,
            subscriptionId: info.subscription.id,
            tid: props.accountData.tid,
            accountData: props.accountData,
            subscriptiondata: info,
          };

          setsubsData(storagedata);

          if (status === "Subscribed") {
            setactiveSub(true); //disabled
            setavailablesub(false); //not disabled
            setloadingMessage("Subscription Active");
            setTimeout(async () => {
              fetchPlans(info);
            }, 2000);
          }

          if (status === "Suspended") {
            setactiveSub(true); //disabled
            setavailablesub(false); //not disabled
            setloadingMessage("Subscription suspended. Please navigate to the M365 Admin centre to reactivate");
          }

          if (status === "Unsubscribed") {
            setactiveSub(true); //disabled
            setavailablesub(true); //disabled
            setloadingMessage("Subscription cancelled");
          }

          if (status === "PendingFulfillmentStart") {
            setactiveSub(false); //not disabled
            setavailablesub(true); //disabled
            setloadingMessage("Activation Required - please activate using the button above");
          }
          setTimeout(async () => {
            storeSubscriptionInDB(storagedata);
            licensingservice();
          }, 300);
        } else {
          console.log("365APPS ~ UNABLE TO RESOLVE");
          setloadingMessage("ERROR resolving subscription");
        }
      }, 100);
    }
    }
  }

  //function to call the get subscription api
  // and set data based upon the subscription

  async function fetchSubscription(subsData?: any) {
    await getSubscription(subsData).then(async (info: any) => {
      if (info) {
        let storagedata = {
          id: info.id,
          subscriptionId: info.id,
          tid: props.accountData.tid,
          accountData: props.accountData,
          subscriptiondata: {
            id: info.id, // purchased SaaS subscription ID
            subscriptionName: info.name, // SaaS subscription name
            offerId: info.offerId, // purchased offer ID
            planId: info.planId, // purchased offer's plan ID
            quantity: info.quantity, // number of purchased seats, might be empty if the plan is not per seat
            subscription: info,
          },
        };

        setsubsData(storagedata);
        storeSubscriptionInDB(storagedata);
        setloadingMessage("OK");
      } else {
        setloadingMessage("Unable to fetch subscription data");
      }
    });
  }

  //function to call the Activate the subscription webhook api

  async function activate() {
    setloadingMessage("Activating Subscription Information...");

    let status: any = subsData?.subscriptiondata.subscription.saasSubscriptionStatus;

    if (status === "PendingFulfillmentStart") {
      await activateSubscription(subsData, props.marketplacetoken).then(async (response: any) => {
        console.log("365APPS ~ activateSubscription ~ response", response);

        if (response === "OK") {
          await fetchSubscription(subsData);
          await fetchPlans(subsData);
          // let info: any = await getSubscription(subsData);

          // let storagedata = {
          //   id: info.subscription.id,
          //   subscriptionId: info.subscription.id,
          //   tid: props.accountData.tid,
          //   accountData: props.accountData,
          //   subscriptiondata: info,
          // };
          await graphUsers();
          // setsubsData(storagedata);
          setavailablesub(false);

          // storeSubscriptionInDB(storagedata);
          await notifyNewSubscription(subsData);
          await  notifyActivationSubscription(subsData);
          setloadingMessage(
            "There's a pending transaction on this subscription and will be completed in a couple of minutes. Until it is completed, some tasks for this subscription aren't available. Please check your email for a confirmation email."
          );
        } else {
          // notifyActivationFailure();
        }
      });
    }
  }

  /*
   *
   *
   *  CANCEL SUBSCRIPTION
   *
   *
   * */
  const [openCancel, setOpenCancel] = React.useState(false);

  const handleClickOpen = () => {
    setOpenCancel(true);
  };

  const handleClose = () => {
    setOpenCancel(false);
  };

  //function to call the Cancel the subscription webhook api
  async function cancelSubscription() {
    setloadingMessage("Cancelling Subscription Information...");

    console.log("365APPS ~ Canceling ~ subsData", subsData);

    let status: any = subsData?.subscriptiondata.subscription.saasSubscriptionStatus;

    if (status === "Subscribed") {
      cancelSubscriptionAPI(subsData).then(async (response: any) => {
        console.log("365APPS ~ cancelSubscription response", response);

        if (response.statusText === "OK") {
          fetchSubscription(subsData);
          // let info: any = await getSubscription(subsData);
          // console.log("365APPS ~ status", status);

          // setsubsData(info);
          // storeSubscriptionInDB(info);
          handleClose();
          notifyCancellation(subsData);

          setloadingMessage("Subscription cancelled");
        } else {
          notifyActivationFailure(subsData);

          setloadingMessage("Error cancelling subscription. please contact support@365Apps.io");

          handleClose();
        }
      });
    }
    setloadingMessage("OK");
  }

  /*
   *
   *
   *  fetch and manage plans
   *
   *
   *
   */

  const [newPlan, setNewPlan] = React.useState();
  const [currentPlan, setcurrentPlan] = React.useState<any>();

  const [openPlan, setOpenPlan] = React.useState(false);

  const handleClickOpen2 = () => {
    setOpenPlan(true);
  };

  const handleClose2 = () => {
    setOpenPlan(false);
  };
  const [value, setValue] = React.useState("");

  //update the local plan
  const handlePlanChange = (event: any) => {
    setValue(event.target.value);

    const result: any = plansData.filter(checkPlan);

    //only include those we are selling
    function checkPlan(plan: any) {
      return plan.planId === event.target.value;
    }

    let currentPlan: any = subsData?.subscriptiondata.planId;

    if (currentPlan != result.planId) {
      setNewPlan(result[0].planId);
    }
  };

  //function to render  the plans data
  function fetchPlans(storedSubData: any) {
    console.log("365APPS ~ fetchPlans ~ storedSubData", storedSubData);
    try {
      setloadingMessage("Fetching  Subscription plans...");

      if (storedSubData) {
        listPlans(storedSubData).then(async (response: any) => {
          console.log("365APPS ~ listPlans ~ response", response);

          let result = response.plans.filter(checkPlan);
          console.log("365APPS ~ listPlans ~ result", result);

          //only include those we are selling
          function checkPlan(plan: any) {
            return plan.isStopSell === false;
          }

          result = result.sort((a: any, b: any) => b.minQuantity - a.minQuantity);

          setplansData(result);

          //find current plan from the list using the subscription planid

          let currentPlan: any = storedSubData?.subscriptiondata
            ? storedSubData?.subscriptiondata.planId
            : storedSubData.planId;

          const result2: any = result.filter(checkPlan2);

          function checkPlan2(plan: any) {
            return plan.planId === currentPlan;
          }

          setcurrentPlan(result2[0]);
          console.log("365APPS ~ listPlans ~ setcurrentPlan", result2[0]);

          setValue(currentPlan);

          setloadingMessage("OK");
        });
      }
    } catch (e) {
      console.log("365APPS ~ listPlans ~ error", e);
      setloadingMessage("Error fetching plans");
    }
  }

  //function to call the Activate the subscription webhook api
  async function UpdatePlan() {
    setloadingMessage("Updating Subscription Information...");

    if (newPlan) {
      console.log("365APPS ~ UpdatePlan ~ newPlan", newPlan);
      console.log("365APPS ~ UpdatePlan ~ newPlan", currentPlan);

      let response: any = await UpdateSubscriptionPlan(subsData, newPlan);
      console.log("365APPS ~ UpdatePlan ~ response", response);
      let res = await response.json();
      console.log("365APPS ~ UpdatePlan ~ response", res);
      console.log("365APPS ~ UpdatePlan ~ response", res.status);

      if (res.status === "OK") {
        //if the reponse is OK
        setOpenPlan(false);
        setavailablesub(true);

        setloadingMessage("Refreshing subscription information...");
        setTimeout(() => {
          fetchSubscription(subsData);
          // setavailablesub(false);
        }, 10000);
      } else {
        // let res = await response.json();
        // console.log("365APPS ~ UpdatePlan ~ response", res);
        let msg = res.error;
        console.log("365APPS ~ UpdatePlan ~ msg", msg);

        let message = await JSON.parse(msg);
        console.log("365APPS ~ UpdatePlan ~ message", message);

        if (message.Message) {
          setloadingMessage("ERROR Updating subscription - " + message.Message);
        } else {
          setloadingMessage("ERROR Updating subscription - " + message.message);
        }

        setTimeout(() => {
          setOpenPlan(false);
        }, 1000);
      }
    }
  }

  /*
   *
   *
   *     UPDATING QUANTITY
   *
   */

  const handleQuantityChange = (event: any) => {
    setQuantity(event.target.value);
  };

  const [openQuantity, setOpenQuantity] = React.useState(false);
  const [Quantity, setQuantity] = React.useState(false);

  const [min, setMin] = React.useState(0);
  const [max, setMax] = React.useState(0);

  const handleClickOpen3 = () => {
    setMin(currentPlan.minQuantity);
    if (currentPlan.maxQuantity > 10000) {
      setMax(500);
    } else {
      setMax(currentPlan.maxQuantity);
    }

    setOpenQuantity(true);
  };

  const handleClose3 = () => {
    setOpenQuantity(false);
  };

  function valuetext(value: number) {
    return value.toString();
  }

  const quantityMenu = () => {
    let menuItems: any = [];

    if (min > 0) {
      for (var i = 1; i < 999; i++) {
        menuItems.push(
          <MenuItem key={i + 6585} value={i}>
            {i}
          </MenuItem>
        );
      }

      return (
        <FormControl fullWidth>
          <Select
            defaultValue={subsData?.subscriptiondata?.quantity ? +subsData?.subscriptiondata?.quantity : 0}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Quantity"
            onChange={handleQuantityChange}
          >
            {menuItems}
          </Select>
        </FormControl>
      );
    } else {
      return <></>;
    }
  };

  //function to call the Activate the subscription webhook api
  async function UpdateQuantity() {
    setloadingMessage("Updating Subscription Quantity...");

    if (Quantity) {
      let response: any = await UpdateSubscriptionQuantity(subsData, Quantity);
      console.log("365APPS ~ UpdateQuantity ~ response", response);

      if (response.statusCode === 200) {
        setloadingMessage("Refreshing subscription Quantity... Please wait");
        setavailablesub(true);
        setTimeout(() => {
          fetchSubscription(subsData);
          setavailablesub(false);
          setloadingMessage("OK -Awaiting confirmation from Microsoft. Please refresh page");
        }, 10000);

        setOpenQuantity(false);
      } else {
        let message = response.Message;
        //  console.log("365APPS ~ UpdateSubscriptionQuantity response", message);

        let me = JSON.parse(response.response.body);
        console.log("365APPS ~ UpdatePlan ~ me", me);
        if (me.Message) {
          setloadingMessage("ERROR Updating subscription - " + me.Message);
        } else {
          setloadingMessage("ERROR Updating subscription  - " + me.message);
        }
      }
    }
    setOpenQuantity(false);
  }

  const [OperationId, setOperationId] = React.useState("");

  //function to call the Activate the subscription webhook api
  async function getOperations() {
    // setloadingMessage("Updating Subscription Quantity...");

    let response: any = await listOperations(subsData);
    console.log("365APPS ~ getOperations ~ response", response);

    // if (response.statusCode === 200) {
    if (response.operations.length != 0) {
      // setloadingMessage("Refreshing subscription Quantity...");

      setOperationId(response.operations[0].id);
      updateOperation(response.operations[0].id);
    } else {
      console.log("365APPS ~ getOperations response", response);

      setloadingMessage("No outstanding operations");
    }
  }

  //function to call the Activate the subscription webhook api
  async function updateOperation(operationId: string) {
    // setloadingMessage("Updating Subscription Quantity...");

    let response: any = await UpdateOperation(subsData, operationId);

    if (response.statusCode === 200) {
    } else {
      let message = response.message;
      console.log("365APPS ~ UpdateSubscriptionQuantity response", message);

      setloadingMessage("ERROR updating outstanding operation");
    }
  }

  async function licensingservice() {
    checkKeygenLicense(props.accountData.tid, props.accountData).then((info: any) => {
      setassigned(info.machines);

      if (info) {
        // store new license in state
        setlicenseData({
          Assigned: info.machines.length,
          data: info.license,
        });
      }
      // setloadingMessage("OK");
    });
  }

  const radioGroupRef = React.useRef<HTMLElement>(null);

  //run check function on component mount
  React.useEffect(() => {
    addchatwidget();
  }, [props.accountData]);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        {/* <CssBaseline /> */}
        <AppBar
          position="absolute"
          // @ts-ignore
          open={open}
        >
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
          
            <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
              Contacts Manager Subscription Dashboard
            </Typography>
            {isAuthenticated ? <SignOutButton /> : <SignInButton />}
          </Toolbar>
        </AppBar>

       
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
            marginTop: "50px",
          }}
        >
          {/* DASHBOARD */}
          {/* DASHBOARD */}
          {/* DASHBOARD */}
          {/* DASHBOARD */}
          {/* DASHBOARD */}
          {/* DASHBOARD */}
          {/* DASHBOARD */}
          {/* DASHBOARD */}

          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {selectedView == "dashboard" ? (
                <>

                  {/* Status Messages */}
                  {/* Status Messages */}
                  {/* Status Messages */}
                  {/* Status Messages */}
                  {/* Status Messages */}
                  {/* Status Messages */}
                  {/* Status Messages */}
                  {/* Status Messages */}
                  {/* Status Messages */}
                  {/* Status Messages */}
                  {/* Status Messages */}
                  {/* Status Messages */}
                  {/* Status Messages */}
                  {/* Status Messages */}
                  {/* Status Messages */}
                  {/* Status Messages */}
                  {/* Status Messages */}
                  {/* Status Messages */}
                  {/* Status Messages */}
                  {/* Status Messages */}
                  {/* Status Messages */}
                  <Grid item xs={12} md={12} lg={12}>
                    <Alert severity="info">
                      Status:
                      <strong>{loadingMessage}</strong>
                    </Alert>

                    {loadingMessage === "ERROR resolving subscription" ? (
                      <div>
                        <Button
                          sx={{
                            m: 1,
                            width: "250px",
                            backgroundColor: "#00a8ff",
                          }}
                          variant="contained"
                          onClick={() => resolve()}
                        >
                          Retry
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Grid>

                  {/* CARD   Subscription Actions */}
                  <Grid item xs={12} md={6} lg={6}>
                    <Paper sx={{ p: 2, display: "flex", flexDirection: "column", minHeight: 370 }}>
                      <Typography
                        variant="h6"
                        sx={{
                          pb: 2,
                        }}
                      >
                        Subscription Actions
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <Button
                          sx={{
                            m: 1,
                            width: "250px",
                            backgroundColor: "#00a8ff",
                          }}
                          variant="contained"
                          target="_blank"
                          // onClick={() => activate()}
                          // disabled={activatesub}
                          href={"https://admin.microsoft.com/#/subscriptions"}
                        >
                          Visit M365 Admin Centre
                        </Button>
                        <Button
                          sx={{
                            m: 1,
                            width: "250px",
                            backgroundColor: "#fb3569",
                            display: activeSub ? "none" : "block",
                          }}
                          variant="contained"
                          onClick={() => activate()}
                          disabled={activeSub}
                        >
                          Activate Subscription
                        </Button>
                        <Button
                          sx={{
                            m: 1,
                            width: "250px",
                            backgroundColor: "#00a8ff",
                          }}
                          variant="contained"
                          disabled={currentPlan ? false : true}
                          onClick={() => handleClickOpen2()}
                        >
                          Change plan
                        </Button>
                        <Button
                          sx={{
                            m: 1,
                            width: "250px",
                            backgroundColor: "#00a8ff",
                          }}
                          variant="contained"
                          disabled={currentPlan ? false : true}
                          onClick={() => handleClickOpen3()}
                        >
                          Change license quantity
                        </Button>
                        <Button
                          sx={{
                            m: 1,
                            width: "250px",
                            backgroundColor: "#00a8ff",
                          }}
                          variant="contained"
                          disabled={availablesub}
                          onClick={() => handleClickOpen()}
                        >
                          Cancel Subscription
                        </Button>

                     
                        {/* Cancel Dialog */}
                        <Dialog
                          fullWidth
                          open={openCancel}
                          onClose={handleClose}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">{"Cancel Subscription?"}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              Are you sure you wish to cancel your Subscription?
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button variant="outlined" onClick={handleClose} autoFocus>
                              No
                            </Button>
                            <Button variant="contained" onClick={cancelSubscription}>
                              Yes
                            </Button>
                          </DialogActions>
                        </Dialog>

                        {/* ChangePlan Dialog */}
                        <Dialog
                          fullWidth
                          open={openPlan}
                          onClose={handleClose2}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">{"Change Plan?"}</DialogTitle>
                          <DialogContent>
                            <RadioGroup
                              onChange={handlePlanChange}
                              ref={radioGroupRef}
                              aria-label="ringtone"
                              name="ringtone"
                              value={value}
                            >
                              {plansData?.map((plan: any, index: number) => {
                                return (
                                  <FormControlLabel
                                    value={plan.planId}
                                    key={"dsfsdfsdfsdf" + index}
                                    label={plan.displayName}
                                    control={
                                      <Radio
                                      // disabled={plan.planId == currentPlan ? true : false}
                                      />
                                    }
                                  />
                                );
                              })}
                            </RadioGroup>
                          </DialogContent>
                          <DialogActions>
                            <Button variant="outlined" onClick={handleClose2} autoFocus>
                              Cancel
                            </Button>
                            <Button variant="contained" onClick={UpdatePlan}>
                              Update Plan
                            </Button>
                          </DialogActions>
                        </Dialog>

                        {/* ChangeQuantity Dialog */}
                        <Dialog
                          fullWidth
                          open={openQuantity}
                          onClose={handleClose3}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">{"Change Quantity?"}</DialogTitle>
                          <DialogContent>
                            {quantityMenu()}
                          </DialogContent>
                          <DialogActions>
                            <Button variant="outlined" onClick={handleClose3} autoFocus>
                              Cancel
                            </Button>
                            <Button variant="contained" onClick={UpdateQuantity}>
                              Update Quantity
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </Box>
                    </Paper>
                  </Grid>

                  {/* CARD  Assign Licenses */}
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={6}
                    sx={{
                      gridRow: "span 2",
                    }}
                  >
                    <Paper sx={{ p: 2, display: "flex", flexDirection: "column", minHeight: 370, height: 300 }}>
                      <Typography
                        variant="h4"
                        sx={{
                          pb: 2,
                        }}
                      >
                        {/* Welcome {props.graphData.givenName} */}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          pb: 2,
                        }}
                      >
                        Assign Licenses
                      </Typography>
                      <Typography
                        sx={{
                          pb: 2,
                        }}
                      >
                        To complete your order process please assign licenses to your users
                      </Typography>

                      <Button
                        sx={{
                          width: "150px",
                        }}
                        variant="contained"
                        disabled={
                          subsData?.subscriptiondata?.subscription?.saasSubscriptionStatus != "Subscribed" &&
                          !graphDataUsers &&
                          availablesub
                        }
                        onClick={() => setselectedView("userTable")}
                      >
                        Assign
                      </Button>
                      <Typography
                        
                        sx={{
                          mt:2,
                          pb: 2,
                        }}
                      >
                          <strong>Profiles Found: </strong> 
                        {graphDataUsers ? graphDataUsers?.length : null}
                      </Typography>
                    </Paper>
                  </Grid>


                  {/* CARD  Subscription Information */}
                  {/* CARD  Subscription Information */}
                  {/* CARD  Subscription Information */}
                  {/* CARD  Subscription Information */}
                  {/* CARD  Subscription Information */}
                  {/* CARD  Subscription Information */}
                  {/* CARD  Subscription Information */}
                  {/* CARD  Subscription Information */}
                  {/* CARD  Subscription Information */}
                  {/* CARD  Subscription Information */}
                  {/* CARD  Subscription Information */}
                  {/* CARD  Subscription Information */}
                  {/* CARD  Subscription Information */}
                  {/* CARD  Subscription Information */}
                  {/* CARD  Subscription Information */}
                  {/* CARD  Subscription Information */}
                  <Grid item xs={12} md={6} lg={6}>
                    <Paper sx={{ p: 2, display: "flex", flexDirection: "column", minHeight: 370 }}>
                      <Typography
                        variant="h6"
                        sx={{
                          pb: 2,
                        }}
                      >
                        Subscription Information
                      </Typography>
                      {subsData && (
                        <div>
                            <Typography sx={{ fontSize: "16px" }}>
                              <strong>Subscription Status: </strong> <br />
                              {subsData ? subsData?.subscriptiondata?.subscription?.saasSubscriptionStatus : null}
                            </Typography>
                          <Typography sx={{ fontSize: "16px" }}>
                            <strong> Subscription Name: </strong> <br />
                            {subsData.subscriptiondata.subscriptionName
                              ? subsData.subscriptiondata.subscriptionName
                              : null}
                          </Typography>
                          <Typography sx={{ fontSize: "16px" }}>
                            <strong> Subscription Id: </strong> <br />
                            {subsData ? subsData.subscriptionId : null}
                          </Typography>
                          <Typography sx={{ fontSize: "16px" }}>
                            <strong> Quantity: </strong> <br />
                            {subsData ? subsData.subscriptiondata?.quantity : null}
                          </Typography>
                          {/* <Typography sx={{ fontSize: "16px" }}>
                        <strong> Subscription Id: </strong> <br />
                        {subsData ? subsData.id : null}
                      </Typography> */}
                          <Typography sx={{ fontSize: "16px" }}>
                            <strong>Term Start Date: </strong> <br />
                            {subsData
                              ? new Date(subsData?.subscriptiondata?.subscription?.term?.startDate).toDateString()
                              : null}
                          </Typography>
                          <Typography sx={{ fontSize: "16px" }}>
                            <strong>Plan Id: </strong> <br />
                            {subsData ? subsData.subscriptiondata?.planId : null}
                          </Typography>
                        </div>
                      )}
                    </Paper>
                  </Grid>

                  {/* CARD  Keygen License Information */}
                  <Grid item xs={12} md={6} lg={6}>
                    <Paper
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        minHeight: 370
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          pb: 2,
                        }}
                      >
                        365Apps License Information
                      </Typography>

             
                      <Typography sx={{ fontSize: "14px" }}>
                     
                      </Typography>
                      <Typography sx={{ fontSize: "14px" }}>
                        <strong>Number of Assigned Licenses: </strong> <br />
                        {licenseData.Assigned ? licenseData.Assigned : "N/A"}
                      </Typography>
                      <Typography sx={{ fontSize: "14px" }}>
                        <strong>Tenant Id: </strong> <br />
                        {props.accountData.tid}
                      </Typography>

                      {/* <Chart /> */}
                    </Paper>
                  </Grid>
      

                  {/* CARD  Administrator / person info */}
                  <Grid item xs={12} md={6} lg={6}>
                    {props.accountData ? (
                      <Paper
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          height: 300,
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            pb: 2,
                          }}
                        ></Typography>
                        <Typography>
                          {/* <strong>First Name: </strong>{" "} */}
                          {/* {props.graphData.givenName} */}
                        </Typography>
                        <Typography>
                          <strong> Purchaser Name: </strong>
                          {subsData ? subsData.subscriptiondata?.subscription.purchaser.emailId : null}
                        </Typography>
                        <Typography>
                          <strong>Beneficiary Name: </strong>
                          {subsData ? subsData.subscriptiondata?.subscription.beneficiary.emailId : null}
                        </Typography>
                        <Typography>{/* <strong>Id: </strong> {props.graphData.id} */}</Typography>
                      </Paper>
                    ) : (
                      <> </>
                    )}
                  </Grid>

                  {/* CARD  support Documents */}
                  <Grid item xs={12} md={6} lg={6}>
                    <Paper
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        minHeight: "200px",
                        height: 300,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          pb: 2,
                        }}
                      >
                        View our Support Documents
                      </Typography>

                      <Typography>
                        <Button
                          sx={{
                            width: "150px",
                          }}
                          variant="contained"
                          // onClick={() => setselectedView("userTable")}
                          href="https://docs.contactsmanager.com"
                          target={"_blank"}
                        >
                          View
                        </Button>
                      </Typography>

                      <Typography
                        variant="h6"
                        sx={{
                          mt:2,
                          pb: 2,
                        }}
                      >
                        For support please use the live chat below or contact via email at:
                      </Typography>
                      <Typography></Typography>
                      <Typography>
                        <Link href="mailto:Support@365apps.io">Support@365Apps.io</Link>
                      </Typography>
                      <Typography>
                        {/* 
                      
                      ADD HUBSPOT CONTACT FORM HERE
                      <HubspotProvider>
                        <MyPage />
                      </HubspotProvider> */}
                      </Typography>
                    </Paper>
                  </Grid>

                  <Grid item xs={12}>
                    {/* <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}><Orders /></Paper> */}
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Container>

          {selectedView == "userTable" ? (
            <UserAssignments
              subsData={subsData}
              userData={graphDataUsers}
              assigned={assigned}
              licenseData={subsData ? subsData.subscriptiondata.quantity : 0}
              tid={props.accountData.tid}
              licenseId={licenseData.data}
              back={() => {
                licensingservice();
                setselectedView("dashboard");
              }}
            ></UserAssignments>
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export interface IDashboardProps {
  // userData: any;
  accountData: any;
  marketplacetoken?: any;
  orgData?: any;
}

export const Dashboard: React.FunctionComponent<IDashboardProps> = (
  props: React.PropsWithChildren<IDashboardProps>
) => {
  const ErrorFallback = (error: any) => {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error}</pre>
      </div>
    );
  };

  // export default function Dashboard(props) {
  return (
    <div>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <DashboardContent
          accountData={props.accountData}
          // userData={props.userData}
          marketplacetoken={props.marketplacetoken}
          orgData={props.orgData}
        />
      </ErrorBoundary>
    </div>
  );
};
